import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Add } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { DateTime } from 'luxon';
import { useDebouncedCallback } from 'use-debounce';

import { Header } from 'commons/components/Header';
import { TableComponent } from 'commons/components/Table';
import { StockTransferStatusEnum } from 'commons/enums';
import { IStockTransfer, IStockTransferItemProps } from 'commons/types';
import {
  getStockTransferBySkuCodeAction,
  getStockTransferSourceProductDetail,
  postStockTransferPickSourceBin,
  postStockTransferRemoveSourceBatch,
} from 'redux-stores/actions';
import { RootReducerInterface } from 'redux-stores/reducers';
import { AppDispatch } from 'redux-stores/store';

import { StockTransferItemForm } from './components';
import { StockTransferTaskStyle as S } from './StockTransferTask.style';
import { sourceRecommendationConfig } from './table-configs';

export const StockTransferTaskSourcePage: React.FC = () => {
  const { pathname } = useLocation();
  const pathSkuId = pathname.split('/').reverse()[0];
  const dispatch = useDispatch<AppDispatch>();
  const { stockTransfer, stockTransferSkuList } = useSelector(
    (state: RootReducerInterface) => state.stockTransfer,
  );

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      getStockTransferBySkuCodeAction({
        skuCode: pathSkuId,
        updateSourceRecommendation: true,
        navigate,
      }),
    );
  }, [pathSkuId, dispatch]);

  const stockTransferTask: IStockTransfer = useMemo(() => {
    const emptyStockTransferTask = {
      transfer_number: '', // get from api, format: `ST-<sku_code>-<short-uuid>`
      status: StockTransferStatusEnum.TRANSFERRING,
      warehouse_id: 1994,
      user: '-',
      name: '-',
      sku_code: pathSkuId,
      source_bin: [],
      destination_bin: [],
      quantity: 0,
    };
    return stockTransfer[pathSkuId] || emptyStockTransferTask;
  }, [pathSkuId, stockTransfer]);

  const [sources, setSources] = useState<Partial<IStockTransferItemProps>[]>(
    structuredClone(stockTransferTask.source_bin),
  );

  const [skuIndex, setSkuIndex] = useState(0);
  useEffect(() => {
    setSkuIndex(stockTransferSkuList.findIndex((sku) => sku === pathSkuId));
  }, [pathSkuId, stockTransferSkuList]);

  useEffect(() => {
    if (
      stockTransferTask?.source_bin &&
      stockTransferTask.source_bin.length > 0
    ) {
      setSources(structuredClone(stockTransferTask.source_bin));
    } else {
      setSources([{}]);
    }
  }, [stockTransferTask.source_bin]);

  const handleAddBatch = (): void => {
    const newSources = structuredClone(sources);
    newSources.push({});
    setSources(newSources);
  };

  const handleRemoveBatch = (): void => {
    const newSources = structuredClone(sources);
    const removedSource = newSources.pop();

    if (removedSource && removedSource.bin && removedSource.inventory_number) {
      postStockTransferRemoveSourceBatch(
        {
          skuCode: stockTransferTask.sku_code,
          removedBin: {
            bin: removedSource.bin,
            inventory_number: removedSource.inventory_number,
          },
        },
        dispatch,
      );
    }
    setSources(newSources);
  };

  const debouncePostStockTransferReplaceBatch = useDebouncedCallback(
    (skuCode: string, replacedSource: IStockTransferItemProps) => {
      postStockTransferRemoveSourceBatch(
        {
          skuCode,
          removedBin: {
            bin: replacedSource.bin,
            inventory_number: replacedSource.inventory_number,
          },
        },
        dispatch,
      );
    },
    500,
    { maxWait: 2000 },
  );

  const handleReplaceBatch = (
    index: number,
    newItem: Partial<IStockTransferItemProps>,
  ): Partial<IStockTransferItemProps> => {
    const newSources = structuredClone(sources);
    const replacedSource = newSources[index];
    if (
      replacedSource &&
      replacedSource.bin &&
      replacedSource.inventory_number &&
      replacedSource.quantity
    ) {
      debouncePostStockTransferReplaceBatch(
        stockTransferTask.sku_code,
        replacedSource as IStockTransferItemProps,
      );
    }
    newSources[index] = newItem;
    setSources(newSources);
    return newItem;
    return {};
  };

  const debouncePostStockTransferPickSourceBin = useDebouncedCallback(
    (skuCode: string, sourceBin: IStockTransferItemProps) => {
      postStockTransferPickSourceBin(
        {
          skuCode,
          sourceBin,
        },
        dispatch,
      );
    },
    500,
    { maxWait: 2000 },
  );

  const debounceGetProductDetail = useDebouncedCallback(
    async (
      skuCode: string,
      index: number,
      item: Partial<IStockTransferItemProps>,
    ) => {
      const { bin, inventory_number: inventoryNumber, quantity } = item;
      if (bin && inventoryNumber) {
        const result = await getStockTransferSourceProductDetail(
          { skuCode, sourceBin: { bin, inventory_number: inventoryNumber } },
          dispatch,
        );
        const newSources = structuredClone(sources);
        let newSource: Partial<IStockTransferItemProps>;
        if (result) {
          newSource = {
            bin: result.bin,
            inventory_number: result.inventory_number,
            expiry_date: new Date(result.expiry_date),
            quantity: 0,
          };
          if (quantity && quantity > 0) {
            const qtyToAdd =
              quantity >= result.quantity ? result.quantity : quantity;
            newSource.quantity = qtyToAdd;
          }
        } else {
          newSource = { bin, inventory_number: inventoryNumber, quantity: 0 };
        }
        newSources[index] = newSource;
        setSources(newSources);
      }
    },
    500,
    {
      maxWait: 2000,
    },
  );

  const handleOnChange = async (
    index: number,
    newItem: Partial<IStockTransferItemProps>,
  ): Promise<void> => {
    const currentSource = sources[index];
    if (!currentSource) {
      return;
    }
    const { bin, inventory_number: inventoryNumber, quantity } = newItem;
    if (
      bin === currentSource.bin &&
      inventoryNumber === currentSource.inventory_number &&
      quantity === currentSource.quantity
    ) {
      /* same data / no update, no need to update to backend */
      return;
    }
    if (bin && inventoryNumber) {
      debounceGetProductDetail(stockTransferTask.sku_code, index, newItem);
    }
  };

  const handleUpdateSource = (
    index: number,
    newSource: IStockTransferItemProps,
  ): void => {
    const {
      bin,
      inventory_number: inventoryNumber,
      expiry_date: expiryDate,
      quantity,
    } = newSource;
    const currentSource = sources[index];
    if (!currentSource) {
      return;
    }
    if (
      bin === currentSource.bin &&
      inventoryNumber === currentSource.inventory_number &&
      currentSource.expiry_date &&
      DateTime.fromJSDate(new Date(expiryDate)).hasSame(
        DateTime.fromJSDate(new Date(currentSource.expiry_date)),
        'day',
      ) &&
      quantity === currentSource.quantity
    ) {
      /* same data / no update, no need to update to backend */
      return;
    }
    if (bin && inventoryNumber && expiryDate && quantity) {
      const newSources = structuredClone(sources);
      newSources[index] = newSource;
      setSources(newSources);
      debouncePostStockTransferPickSourceBin(
        stockTransferTask.sku_code,
        newSource,
      );
    }
  };

  const handleBackToSkuListPage = (): void => {
    navigate('/stock-transfer-list/source');
  };

  const [nextSku, setNextSku] = useState<string | null>(null);
  useEffect(() => {
    const nextSkuIndex = skuIndex + 1;
    if (
      nextSkuIndex >= 0 &&
      nextSkuIndex < stockTransferSkuList.length &&
      stockTransferSkuList[nextSkuIndex]
    ) {
      setNextSku(stockTransferSkuList[nextSkuIndex]);
    } else {
      setNextSku(null);
    }
  }, [stockTransferSkuList, skuIndex]);

  const handleGoToNextSku = (): void => {
    if (nextSku) {
      navigate(`/stock-transfer-task/source/${nextSku}`);
    }
  };

  return (
    <>
      <Header title="Stock Transfer Task | Source" />

      {/* Body */}
      <S.ContentWrapper>
        <S.SubtitleWrapper container>
          <S.Subtitle item xs={12}>
            {pathSkuId}
          </S.Subtitle>
          <S.SubHeaderText>
            {stockTransferTask.name || '-'} <br />
            {/* Total Quantity Requested: {stockTransferTask.quantity} */}
          </S.SubHeaderText>
        </S.SubtitleWrapper>

        <S.ItemContentWrapper container>
          <S.ItemContentWrapper container>
            <Grid item xs={12}>
              <S.SubHeaderText>Source Bin</S.SubHeaderText>
            </Grid>
            {/* bin recommendation */}
            <TableComponent
              data={stockTransferTask.source_bin_recommendations || []}
              config={sourceRecommendationConfig}
              additionalTableConfig={{ bodyFontSize: '12px' }}
            />
          </S.ItemContentWrapper>
        </S.ItemContentWrapper>
        {/* main forms */}
        {sources.map((source, index) => (
          <StockTransferItemForm
            index={index}
            item={source}
            type="pick"
            onAllFilled={handleUpdateSource}
            onChanged={handleOnChange}
            onReplace={handleReplaceBatch}
          />
        ))}
        <Grid container style={{ marginTop: 8 }}>
          <Grid item xs={6} style={{ textAlign: 'center' }}>
            <S.RedButtonText
              onClick={handleRemoveBatch}
              disabled={sources.length === 1}
            >
              REMOVE BATCH
            </S.RedButtonText>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <S.BlueButtonText onClick={handleAddBatch}>
              <Add /> ADD BATCH
            </S.BlueButtonText>
          </Grid>
        </Grid>
        <S.BlankWhiteSpace />
      </S.ContentWrapper>

      {/* Footer */}
      <S.FooterWrapper>
        <Grid container>
          <Grid item xs={6}>
            <S.SecondaryButton onClick={handleBackToSkuListPage}>
              BACK TO SKU LIST
            </S.SecondaryButton>
          </Grid>
          <Grid item xs={6}>
            <S.PrimaryButton onClick={handleGoToNextSku} disabled={!nextSku}>
              NEXT SKU
            </S.PrimaryButton>
          </Grid>
        </Grid>
      </S.FooterWrapper>
    </>
  );
};
