import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ArrowForwardIos } from '@mui/icons-material';
import { Grid } from '@mui/material';

import UserInfoHeader from './components/UserInfoHeader.component';
import S from './HomePage.styles';

const HomePage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div>
      <UserInfoHeader />
      <h3>Inbound</h3>

      {/* Menu Options */}
      {/* Replenishment */}
      <S.MenuOption onClick={() => navigate('/assign-replenish')}>
        <Grid container>
          <Grid item xs={2}>
            <S.MenuIcon
              src="/assets/replenishment-icon.svg"
              alt="replenishment-icon"
            />
          </Grid>
          <Grid item xs={8}>
            <S.MenuText>Replenishment</S.MenuText>
          </Grid>
          <Grid item xs={2}>
            <ArrowForwardIos
              fontSize="small"
              style={{ marginTop: 14, textAlign: 'right' }}
            />
          </Grid>
        </Grid>
      </S.MenuOption>

      <S.MenuOption onClick={() => navigate('/stock-transfer-list/source')}>
        <Grid container>
          <Grid item xs={2}>
            <S.MenuIcon
              src="/assets/stock-transfer-icon.svg"
              alt="stock-transfer-icon"
            />
          </Grid>
          <Grid item xs={8}>
            <S.MenuText>Stock Transfer</S.MenuText>
          </Grid>
          <Grid item xs={2}>
            <ArrowForwardIos
              fontSize="small"
              style={{ marginTop: 14, textAlign: 'right' }}
            />
          </Grid>
        </Grid>
      </S.MenuOption>

      <h3>Outbound</h3>

      {/* Menu Options */}
      {/* Picking */}
      <S.MenuOption onClick={(): void => navigate('/assign-task')}>
        <Grid container>
          <Grid item xs={2}>
            <S.MenuIcon src="/assets/picking-icon.svg" alt="picking-icon" />
          </Grid>
          <Grid item xs={8}>
            <S.MenuText>Picking</S.MenuText>
          </Grid>
          <Grid item xs={2}>
            <ArrowForwardIos
              fontSize="small"
              style={{ marginTop: 14, textAlign: 'right' }}
            />
          </Grid>
        </Grid>
      </S.MenuOption>

      {/* Checking */}
      {/* <S.MenuOption onClick={() => navigate('/')}>
        <Grid container>
          <Grid item xs={2}>
            <S.MenuIcon src="/assets/checking-icon.svg" alt="checking-icon" />
          </Grid>
          <Grid item xs={8}>
            <S.MenuText>Checking</S.MenuText>
          </Grid>
          <Grid item xs={2}>
            <ArrowForwardIos
              fontSize="small"
              style={{ marginTop: 14, textAlign: 'right' }}
            />
          </Grid>
        </Grid>
      </S.MenuOption> */}

      {/* Packing */}
      <S.MenuOption onClick={() => navigate('/')}>
        <Grid container>
          <Grid item xs={2}>
            <S.MenuIcon src="/assets/packing-icon.svg" alt="packing-icon" />
          </Grid>
          <Grid item xs={8}>
            <S.MenuText>Packing</S.MenuText>
          </Grid>
          <Grid item xs={2}>
            <ArrowForwardIos
              fontSize="small"
              style={{ marginTop: 18, textAlign: 'right' }}
            />
          </Grid>
        </Grid>
      </S.MenuOption>

      <h3>Admin</h3>

      {/* Menu Options */}
      {/* Dashboard */}
      <S.MenuOption onClick={(): void => navigate('/dashboard')}>
        <Grid container>
          <Grid item xs={2}>
            {/* Placeholder icon due to unavailability */}
            <S.MenuIcon src="/assets/checking-icon.svg" alt="dashboard-icon" />
          </Grid>
          <Grid item xs={8}>
            <S.MenuText>Dashboard</S.MenuText>
          </Grid>
          <Grid item xs={2}>
            <ArrowForwardIos
              fontSize="small"
              style={{ marginTop: 18, textAlign: 'right' }}
            />
          </Grid>
        </Grid>
      </S.MenuOption>
    </div>
  );
};

export default HomePage;
