export enum SwipeRxAPIResources {
  PICKING_TASK = 'picking-task',
  USER = 'user',
  ASSIGN_BASKET = 'basket-assignment',
  PICKING_JOB = 'picking-job',
  ALERT_NOTIFICATION = 'alert-notification',
  DASHBOARD = 'dashboard',
  REPLENISH = 'replenish',
  REPLENISH_JOB = 'replenish-job',
  STOCK_TRANSFER = 'stock-transfer',
}
