import { IStockTransfer, IStockTransferItemProps } from 'commons/types';

import { SwipeRxWmsApi } from '../resources';
import { SwipeRxAPIResources } from '../resources/types';

import {
  IGetProductDetailRequest,
  IGetStockTransferBySkuCodeRequest,
  IGetStockTransferBySkuCodeResponse,
  IPostPickSourceBinRequest,
  IStockTransferAddBinInfoDestinationReq,
  IStockTransferAddBinInfoSourceReq,
  IStockTransferCancelReq,
  IStockTransferCompleteReq,
  IStockTransferCreateReq,
} from './types';
import { IPostRemoveBatchRequest } from './types/post-remove-batch.type';

class SwipeRxWmsApiStockTransfer extends SwipeRxWmsApi {
  constructor() {
    super(SwipeRxAPIResources.STOCK_TRANSFER);
  }

  async getStockTransfers(): Promise<IStockTransfer[]> {
    const response = await this.get(`${this.apiResource}`);
    if (!response.data) throw new Error('Data not found');
    return response.data;
  }

  async getStockTransferBySkuCode(
    params: IGetStockTransferBySkuCodeRequest,
  ): Promise<IGetStockTransferBySkuCodeResponse> {
    const response = await this.get(
      `${this.apiResource}/get-by-sku-code`,
      params,
    );
    if (!response.data) throw new Error('Data not found');
    return response.data;
  }

  async addNewStockTransfer(
    payload: IStockTransferCreateReq,
  ): Promise<IStockTransfer> {
    const response = await this.post(`${this.apiResource}/create`, payload);
    if (!response.data) throw new Error('Data not found');
    return response.data as IStockTransfer;
  }

  async addBinInfoSourceToStockTransfer(
    payload: IStockTransferAddBinInfoSourceReq,
  ): Promise<IStockTransfer> {
    const response = await this.post(
      `${this.apiResource}/add-bin-info-source`,
      payload,
    );
    if (!response.data) throw new Error('Data not found');
    return response.data as IStockTransfer;
  }

  async getSourceProductDetail(
    skuCode: string,
    sourceBin: Pick<IStockTransferItemProps, 'bin' | 'inventory_number'>,
  ): Promise<IStockTransferItemProps> {
    const query: IGetProductDetailRequest = {
      bin: sourceBin.bin,
      sku_code: skuCode,
      inventory_number: sourceBin.inventory_number,
    };
    const result = await this.get(
      `${this.apiResource}/source/product-detail`,
      query,
    );
    if (result && result.data) {
      return result.data;
    }
    throw new Error('Failed to get source product detail');
  }

  async postPickSourceBin(
    skuCode: string,
    sourceBin: IStockTransferItemProps,
  ): Promise<void> {
    const payload: IPostPickSourceBinRequest = {
      sku_code: skuCode,
      bin: sourceBin.bin,
      inventory_number: sourceBin.inventory_number,
      quantity: sourceBin.quantity,
      expiry_date: sourceBin.expiry_date,
    };
    await this.post(`${this.apiResource}/source/pick`, payload);
  }

  async postRemoveSourceBatch(
    skuCode: string,
    sourceBin: Pick<IStockTransferItemProps, 'bin' | 'inventory_number'>,
  ): Promise<void> {
    const payload: IPostRemoveBatchRequest = {
      sku_code: skuCode,
      bin: sourceBin.bin,
      inventory_number: sourceBin.inventory_number,
    };
    await this.post(`${this.apiResource}/source/remove-batch`, payload);
  }

  async addBinInfoDestinationToStockTransfer(
    payload: IStockTransferAddBinInfoDestinationReq,
  ): Promise<IStockTransfer> {
    const response = await this.post(
      `${this.apiResource}/add-bin-info-destination`,
      payload,
    );
    if (!response.data) throw new Error('Data not found');
    return response.data as IStockTransfer;
  }

  async cancelStockTransfers(
    payload: IStockTransferCancelReq,
  ): Promise<IStockTransfer> {
    const response = await this.post(`${this.apiResource}/cancel`, payload);
    if (!response.data) throw new Error('Data not found');
    return response.data;
  }

  async completeStockTransfers(
    payload: IStockTransferCompleteReq,
  ): Promise<void> {
    await this.post(`${this.apiResource}/complete`, payload);
  }
}

export const swipeRxWmsApiStockTransfer = new SwipeRxWmsApiStockTransfer();
