import { NavigateFunction } from 'react-router-dom';

import { createAsyncThunk } from '@reduxjs/toolkit';

import { PRIMARY_RED } from 'commons/styles/colors';
import { IStockTransfer, IStockTransferItemProps } from 'commons/types';
import { defaultActionProcess } from 'commons/utils/defaultActionProcess';
import { RootReducerInterface } from 'redux-stores/reducers';
import {
  setUpdateStockTransfer,
  setStockTransfers,
  removeStockTransfer,
} from 'redux-stores/reducers/stockTransfer.reducer';
import { snackbarSetData } from 'redux-stores/reducers/utilityReducer';
import { AppDispatch } from 'redux-stores/store';
import { swipeRxWmsApiStockTransfer } from 'services/apis/SwipeRxWmsApiStockTransfer';

export const addBinInfoSourceAction = createAsyncThunk(
  'stockTransfer/addBinInfoSourceAction',
  async (
    params: { skuCode: string; binNumber: string },
    { dispatch, getState },
  ) => {
    const { skuCode, binNumber } = params;
    const state: RootReducerInterface = getState() as RootReducerInterface;
    const { stockTransfer } = state;
    let currentStockTransfer: IStockTransfer | undefined = stockTransfer
      .stockTransfer[skuCode]
      ? structuredClone(stockTransfer.stockTransfer[skuCode])
      : undefined;
    defaultActionProcess(async () => {
      if (currentStockTransfer) {
        const existingBin = currentStockTransfer.bin_info_source.find(
          (item) => item.bin_number === binNumber,
        );
        if (!existingBin) {
          const updatedStockTransfer =
            await swipeRxWmsApiStockTransfer.addBinInfoSourceToStockTransfer({
              transfer_number: currentStockTransfer.transfer_number,
              bin_number: binNumber,
              sku_code: skuCode,
            });
          currentStockTransfer = updatedStockTransfer;
        }
      } else {
        const newData = await swipeRxWmsApiStockTransfer.addNewStockTransfer({
          sku_code: skuCode,
          bin_number: binNumber,
        });
        currentStockTransfer = newData;
      }

      dispatch(setUpdateStockTransfer(currentStockTransfer));
    }, dispatch);
    return undefined;
  },
);

export const addBinInfoDestinationAction = createAsyncThunk(
  'stockTransfer/addBinInfoDestinationAction',
  async (
    params: { skuCode: string; binNumber: string },
    { dispatch, getState },
  ) => {
    const { skuCode, binNumber } = params;
    const state: RootReducerInterface = getState() as RootReducerInterface;
    const { stockTransfer } = state;
    const currentStockTransfer: IStockTransfer | undefined = stockTransfer
      .stockTransfer[skuCode]
      ? structuredClone(stockTransfer.stockTransfer[skuCode])
      : undefined;
    defaultActionProcess(async () => {
      if (!currentStockTransfer) {
        throw new Error(
          `SKU ${skuCode} is not yet picked, please add the SKU on the previous step first`,
        );
      }
      const existingBin = currentStockTransfer.bin_info_destination.find(
        (item) => item.bin_number === binNumber,
      );
      if (!existingBin) {
        const updatedStockTransfer =
          await swipeRxWmsApiStockTransfer.addBinInfoDestinationToStockTransfer(
            {
              transfer_number: currentStockTransfer.transfer_number,
              bin_number: binNumber,
              sku_code: skuCode,
            },
          );
        dispatch(setUpdateStockTransfer(updatedStockTransfer));
      }
    }, dispatch);
  },
);

export const getStockTransferAction = createAsyncThunk(
  'stockTransfer/getStockTransferAction',
  async (_, { dispatch }) => {
    defaultActionProcess(async () => {
      const data = await swipeRxWmsApiStockTransfer.getStockTransfers();
      dispatch(setStockTransfers(data));
    }, dispatch);
  },
);

export const getStockTransferBySkuCodeAction = createAsyncThunk(
  'stockTransfer/getStockTransferBySkuCodeAction',
  async (
    params: {
      skuCode: string;
      updateSourceRecommendation?: boolean;
      navigate: NavigateFunction;
    },
    { dispatch },
  ) => {
    try {
      const data = await swipeRxWmsApiStockTransfer.getStockTransferBySkuCode({
        sku_code: params.skuCode,
        update_source_recommendation: params.updateSourceRecommendation,
      });
      dispatch(setUpdateStockTransfer(data));
    } catch (error: any) {
      params.navigate('/stock-transfer-list/source');
      dispatch(
        snackbarSetData({
          open: true,
          message: error.message,
          color: PRIMARY_RED,
        }),
      );
    }
  },
);

export const getStockTransferSourceProductDetail = async (
  params: {
    skuCode: string;
    sourceBin: Pick<IStockTransferItemProps, 'bin' | 'inventory_number'>;
  },
  dispatch: AppDispatch,
): Promise<IStockTransferItemProps | undefined> => {
  const { skuCode, sourceBin } = params;
  const result = await defaultActionProcess(
    async () => {
      const response = await swipeRxWmsApiStockTransfer.getSourceProductDetail(
        skuCode,
        sourceBin,
      );
      return response;
    },
    dispatch,
    false,
  );
  return result;
};

export const postStockTransferPickSourceBin = async (
  params: { skuCode: string; sourceBin: IStockTransferItemProps },
  dispatch: AppDispatch,
): Promise<void> => {
  const { skuCode, sourceBin } = params;
  defaultActionProcess(
    async () => {
      await swipeRxWmsApiStockTransfer.postPickSourceBin(skuCode, sourceBin);
    },
    dispatch,
    false,
  );
};

export const postStockTransferRemoveSourceBatch = async (
  params: {
    skuCode: string;
    removedBin: Pick<IStockTransferItemProps, 'bin' | 'inventory_number'>;
  },
  dispatch: AppDispatch,
): Promise<void> => {
  const { skuCode, removedBin } = params;
  defaultActionProcess(
    async () => {
      await swipeRxWmsApiStockTransfer.postRemoveSourceBatch(
        skuCode,
        removedBin,
      );
    },
    dispatch,
    false,
  );
};
export const cancelStockTransferAction = createAsyncThunk(
  'stockTransfer/cancelStockTransferAction',
  async (params: { transfer_number: string }, { dispatch }) => {
    defaultActionProcess(async () => {
      const data =
        await swipeRxWmsApiStockTransfer.cancelStockTransfers(params);
      dispatch(removeStockTransfer(data));
    }, dispatch);
  },
);

export const completeStockTransferAction = createAsyncThunk(
  'stockTransfer/completeStockTransferAction',
  async (
    params: { transfer_numbers: string[]; postAction: () => void },
    { dispatch },
  ) => {
    const { transfer_numbers: transferNumbers, postAction } = params;
    defaultActionProcess(async () => {
      await swipeRxWmsApiStockTransfer.completeStockTransfers({
        transfer_numbers: transferNumbers,
      });
      postAction();
    }, dispatch);
  },
);
