import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Close } from '@mui/icons-material';
import { Grid, IconButton } from '@mui/material';

import { Header } from 'commons/components/Header';
import { TableComponent } from 'commons/components/Table';
import { IStockTransfer } from 'commons/types';
import {
  addBinInfoSourceAction,
  cancelStockTransferAction,
  getStockTransferAction,
} from 'redux-stores/actions/stockTransfer.action';
import { RootReducerInterface } from 'redux-stores/reducers';
import { AppDispatch } from 'redux-stores/store';

import { BinSkuSearch, StockTransferStepJourney } from './components';
import RemoveStockTransferDialog from './components/remove-task-dialog.modal';
import { sourceConfig } from './config';
import { stockTransferListStyle as S } from './StockTransferList.style';

export const StockTransferListSourcePage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { stockTransfer } = useSelector(
    (state: RootReducerInterface) => state.stockTransfer,
  );

  const [isValidatedNextStep, setValidatedNextStep] = useState(false);
  const [removedTask, setRemovedTask] = useState<IStockTransfer | null>(null);
  const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);

  const handleBackToMainMenu = (): void => {
    navigate('/');
  };

  const handleNextStep = (): void => {
    navigate('/stock-transfer-list/destination');
  };

  const handleAddSkuBin = (sku: string, bin: string): void => {
    if (sku && bin) {
      dispatch(addBinInfoSourceAction({ skuCode: sku, binNumber: bin }));
    }
  };

  useEffect(() => {
    dispatch(getStockTransferAction());
  }, [dispatch]);

  useEffect(() => {
    const stockTransferList = Object.values(stockTransfer);
    if (stockTransferList.length === 0) {
      setValidatedNextStep(false);
      return;
    }
    const invalidSource = stockTransferList.find(
      (stockTransferItem) =>
        stockTransferItem.source_bin.length === 0 ||
        stockTransferItem.source_bin.reduce(
          (total, source) => total + source.quantity,
          0,
        ) <= 0,
    );
    if (invalidSource) {
      setValidatedNextStep(false);
      return;
    }

    setValidatedNextStep(true);
  }, [stockTransfer]);

  const onRemoveModalOpen = (task: IStockTransfer) => {
    setOpenRemoveModal(true);
    setRemovedTask(task);
  };
  const onRemoveModalClose = () => setOpenRemoveModal(false);

  const removeSKU = async (task: IStockTransfer) => {
    dispatch(
      cancelStockTransferAction({ transfer_number: task.transfer_number }),
    );
    setOpenRemoveModal(false);
  };

  const renderStockTransferData = (stockTransferItem: IStockTransfer) => {
    const skuButtonText =
      stockTransferItem.source_bin.length === 0 ? 'START' : 'EDIT';
    return (
      <Grid container>
        <Grid
          item
          xs={1}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
          }}
        >
          <IconButton
            onClick={() => onRemoveModalOpen(stockTransferItem)}
            style={{ borderRadius: 0 }}
          >
            <Close fontSize="small" />
          </IconButton>
        </Grid>
        <Grid item xs={11}>
          <Grid container>
            <S.SKUGridWrapper container>
              <S.SKUGrid item xs={8}>
                SKU {stockTransferItem.sku_code}
              </S.SKUGrid>
              <S.SKUButtonGrid item xs={4}>
                <S.SecondaryButton
                  onClick={() => {
                    navigate(
                      `/stock-transfer-task/source/${stockTransferItem.sku_code}`,
                    );
                  }}
                >
                  {skuButtonText}
                </S.SecondaryButton>
              </S.SKUButtonGrid>
            </S.SKUGridWrapper>

            <S.TableInfoGridWrapper container>
              <S.TableInfoGrid item xs={12}>
                <TableComponent
                  config={sourceConfig}
                  data={stockTransferItem.bin_info_source}
                />
              </S.TableInfoGrid>
            </S.TableInfoGridWrapper>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Header
        title="Stock Transfer List"
        prevPageHandler={handleBackToMainMenu}
      />

      <S.ContentWrapper>
        <StockTransferStepJourney currentStep="source" />
        <BinSkuSearch onAddClicked={handleAddSkuBin} />

        {Object.values(stockTransfer).map((stockTransferItem) =>
          renderStockTransferData(stockTransferItem),
        )}
      </S.ContentWrapper>

      <S.FooterWrapper>
        <S.PrimaryButton
          onClick={handleNextStep}
          disabled={!isValidatedNextStep}
        >
          PROCEED TO NEXT STEP
        </S.PrimaryButton>

        {openRemoveModal && (
          <RemoveStockTransferDialog
            task={removedTask}
            onClose={onRemoveModalClose}
            openModal={openRemoveModal}
            onConfirm={removeSKU}
          />
        )}
      </S.FooterWrapper>
    </>
  );
};
